import React, { useState, useEffect } from 'react'
import '../../../utils/styles/SuperDashboard.css'
import { AddPictureModal } from './AddPictureModal';
import { AddPictureModalCampus } from './AddPictureModalCampus';
import AxiosClient from '../../../shared/plugins/axios';

export default function SuperImages() {
    const images = [
        {
            title: "Bugambilias Grande",
            alt: "Descripción 1"
        },
        {
            title: "Bugambilias Chico",
            alt: "Descripción 2"
        },
        {
            title: "Centro Grande",
            alt: "Descripción 3"
        },
        {
            title: "Centro Chico",
            alt: "Descripción 4"
        },
        {
            title: "Cuautla Grande",
            alt: "Descripción 5"
        },
        {
            title: "Cuautla Chico",
            alt: "Descripción 6"
        },
        {
            title: "Coyoacán Grande",
            alt: "Descripción 5"
        },
        {
            title: "Coyoacán Chico",
            alt: "Descripción 6"
        }
    ];

    const [isPicture, setIsPicture] = useState(false);
    const [objeto, setObjeto] = useState("");
    const [imagenes, setImagenes] = useState([{}]);

    useEffect(() => {
        setImagenes([{}]);
        const cargarImagen = async (nombre) => {
            try {
                const response = await AxiosClient({
                    url: "/uploads/imageCampus/" + nombre,
                    method: "GET",
                    responseType: 'arraybuffer'
                });
                console.log(response);
                if (!response.error) {
                    const imageBlob = new Blob([response]);
                    const imageUrl = URL.createObjectURL(imageBlob);
                    setImagenes((imagenes) => [...imagenes, { [nombre]: imageUrl }]);
                    console.log(imagenes)
                }
            } catch (err) {

                console.log(err);
            }
        }
        [0, 1, 2, 3, 4, 5, 6, 7].forEach((numero, index) => {
            cargarImagen(`web${index}.jpg`);
        });
    }, [isPicture]);

    return (
        <div style={{
            padding: "2rem",
            width: "100%",
            height: "100%"
        }}>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "2rem",
                    padding: "1rem"
                }}>
                {images.map((image, index) => (
                    <div key={index}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            padding: "1rem",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
                        }}

                    >
                        <h3
                            style={{
                                marginBottom: "1rem",
                                fontSize: "1.2rem",
                                fontWeight: "bold"
                            }}>
                            {image.title}
                        </h3>
                        <img
                            onClick={() => {
                                setObjeto(`web${index}`);
                                setIsPicture(true);
                            }}
                            src={imagenes.find(img => Object.keys(img)[0] === `web${index}.jpg`)?.[`web${index}.jpg`] || ""}
                            alt={image.alt}
                            style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "cover",
                                borderRadius: "8px",
                                cursor: "pointer"
                            }}
                        />
                    </div>
                ))}
            </div>

            <AddPictureModalCampus isOpen={isPicture} onClose={() => setIsPicture(false)} numeroFoto={objeto} />
        </div>
    );
}